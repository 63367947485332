// ########################################
// IMPORT
// ========================================
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// ========================================
// Context
import { useAuth } from "../contexts/auth";
// ========================================
// Redux
import { toggleNav } from "../redux/reducers/ui";
import { setCurrentTicketByObject } from "../redux/reducers/tickets";
import { toggleCloak, patchCurrentUser } from "../redux/reducers/user";
import { fetchConsumerById } from "../redux/reducers/consumers";
import {
  notificationsSelectors,
  patchCurrentNotification,
} from "../redux/reducers/notifications";
import { publicWebpageSelectors } from "../redux/reducers/publicWebpages";
// ========================================
// Custom components
import AppNotificationItem from "./App_NotificationItem";
// ========================================
// Material UI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import SwipeableDrawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
// ----------------------------------------
// Icons
import SvgIcon from "@mui/material/SvgIcon";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountBox from "@mui/icons-material/AccountBox";
import CircleNotifications from "@mui/icons-material/CircleNotifications";
import Logout from "@mui/icons-material/Logout";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import { mdiGhostOff } from "@mdi/js";
import { DateTime } from "luxon";
// ########################################
// FUNCTIONAL COMPONENT
const Header = (props) => {
  const { t, i18n } = useTranslation("components", { keyPrefix: "App_Header" });
  // ========================================
  // VARIABLES
  // ----------------------------------------
  // Local state
  const [anchorElAccount, setAnchorElAccount] = React.useState(null);
  const [anchorElResearch, setAnchorElResearch] = React.useState(null);
  const [anchorElNotifications, setAnchorElNotifications] =
    React.useState(null);
  const [anchorElLang, setAnchorElLang] = React.useState(null);
  // ----------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  // ----------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  const webpages = useSelector((state) =>
    publicWebpageSelectors.selectAll(state),
  );
  const navIsOpen = useSelector((state) => state.ui.navIsOpen);
  const currentPage = useSelector((state) => state.ui.currentPage);
  const currentUser = useSelector((state) => state.user.currentUser);
  const locale = useSelector(
    (state) => state.user.currentUser && state.user.currentUser.locale,
  );
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const notificationList = useSelector((state) =>
    notificationsSelectors.selectAll(state),
  );
  const notificationStatus = useSelector((state) => state.notifications.status);
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  // ========================================
  // FUNCTIONS
  // ----------------------------------------
  // Toggles the navigation
  const handleToggleNav = () => {
    dispatch(toggleNav({ isOpen: !navIsOpen }));
  };
  // ----------------------------------------
  // Disable cloaking
  const handleCloak = () => {
    dispatch(
      fetchConsumerById({
        requestingUser: currentAuth,
        userId: currentUser._id,
      }),
    );
    dispatch(setCurrentTicketByObject({ ticket: null }));
    dispatch(toggleCloak({ cloak: false }));
  };
  // ========================================
  useEffect(() => {
    if (locale === null || locale === undefined) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage(locale);
    }
  }, [locale]); // eslint-disable-line react-hooks/exhaustive-deps
  // ========================================
  // RETURN
  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={!isXS && !isSM ? { zIndex: 1201 } : { zIndex: 1100 }}
    >
      <Toolbar className="bg-gradient-secondary bg-opacity-75pct bd-filt-blur-12px">
        <Grid container wrap="nowrap" alignItems="center">
          <Box sx={{ color: "inherit", textDecoration: "inherit" }}>
            <Grid item className="flex-grow-0">
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item>
                  {(isXS || (isSM && currentAuth) || props.forceNavDrawer) && (
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      onClick={handleToggleNav}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                  {!isXS && !(isSM && currentAuth) && (
                    <img
                      src="logo42.png"
                      alt={process.env.REACT_APP_TITLE}
                      height="42"
                      className="rounded me-2"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  sx={
                    !isXS && !(isSM && currentAuth)
                      ? { width: "1px", paddingTop: "1px" }
                      : {}
                  }
                >
                  <Typography
                    variant={
                      !isXS && !(isSM && currentAuth) ? "overline" : "h2"
                    }
                    component="div"
                    sx={{ lineHeight: "0.9rem" }}
                  >
                    {!isXS && !(isSM && currentAuth)
                      ? process.env.REACT_APP_TITLE
                      : currentPage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid item className="flex-grow-1">
            {(isXS || isSM) && (
              <Grid container justifyContent="flex-end">
                <Grid item>{props.children}</Grid>
              </Grid>
            )}
          </Grid>
          {!isXS && !(isSM && currentAuth) && (
            <Grid item className="flex-grow-0">
              {webpages
                .filter((page) => page.type === "home")
                .map((page) => (
                  <Button
                    key={page.url}
                    component={NavLink}
                    to={`/${page.url}`}
                    color="inherit"
                    className="mx-1"
                  >
                    {page.title}
                  </Button>
                ))}
              {webpages
                .filter((page) => page.type === "page")
                .filter((page) => page.status === "published")
                .map((page) => (
                  <Button
                    key={page.url}
                    component={NavLink}
                    to={`/${page.url}`}
                    color="inherit"
                    className="mx-1"
                  >
                    {page.title}
                  </Button>
                ))}
              <Button
                color="inherit"
                endIcon={<ExpandMore />}
                className="mx-1"
                onClick={(e) => setAnchorElResearch(e.currentTarget)}
              >
                {t("Research")}
              </Button>
              {currentAuth && currentPage === "" && (
                <Button
                  component={NavLink}
                  to={
                    currentUser &&
                    currentUser.primaryRole &&
                    currentUser.primaryRole === "participant"
                      ? "/tasks"
                      : "/studies"
                  }
                  color="inherit"
                  className="mx-1"
                >
                  {t("Dashboard")}
                </Button>
              )}
              {!currentAuth && (
                <Button
                  component={NavLink}
                  to="/sign-in"
                  color="inherit"
                  className="mx-1"
                >
                  {t("Sign in")}
                </Button>
              )}
            </Grid>
          )}
          {ghostUser && currentUser && (
            <Grid item className="flex-grow-0">
              <Tooltip
                arrow
                title={t("Cloaking as {{ email }}", {
                  email: currentUser.email,
                })}
                placement="bottom"
              >
                <Button
                  startIcon={
                    <SvgIcon>
                      <path d={mdiGhostOff} />
                    </SvgIcon>
                  }
                  component={NavLink}
                  to={
                    currentUser.studyEnrollmentList.length > 0
                      ? `/volunteers?studyId=${currentUser.studyEnrollmentList[0].studyId}&consumerId=${currentUser._id}`
                      : `/volunteers?studyId=not-enrolled&consumerId=${currentUser._id}`
                  }
                  color="inherit"
                  size="small"
                  variant="outlined"
                  className="ms-2"
                  onClick={handleCloak}
                >
                  {t("Uncloak")}
                </Button>
              </Tooltip>
            </Grid>
          )}
          <Grid item className="flex-grow-0">
            {!ghostUser && currentUser && (
              <>
                <IconButton
                  size="large"
                  aria-label={t("account of current user")}
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(e) => setAnchorElAccount(e.currentTarget)}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </>
            )}
            {!ghostUser && currentUser && notificationList && (
              <IconButton
                aria-label="notifications"
                color="inherit"
                onClick={(e) => setAnchorElNotifications(e.currentTarget)}
              >
                <Badge
                  badgeContent={
                    notificationList.filter((notif) => !notif.isRead).length
                  }
                  color="error"
                >
                  <CircleNotifications />
                </Badge>
              </IconButton>
            )}
            {!currentAuth && (
              <IconButton
                size="large"
                aria-label={t("language")}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => setAnchorElLang(e.currentTarget)}
                color="inherit"
              >
                <LanguageIcon />
              </IconButton>
            )}
            {/* MENUES */}
            <Menu
              id="menu-studies"
              anchorEl={anchorElResearch}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElResearch)}
              onClose={() => setAnchorElResearch(null)}
            >
              {webpages
                .filter((page) => page.type === "study")
                .map((page) => (
                  <MenuItem
                    key={page.url}
                    component={NavLink}
                    to={`/${page.url}`}
                    color="inherit"
                    className="px-3 p-2"
                    onClick={() => setAnchorElResearch(null)}
                  >
                    <ListItemText>{page.title}</ListItemText>
                  </MenuItem>
                ))}
            </Menu>
            {notificationList && isXS ? (
              <SwipeableDrawer
                anchor="right"
                open={Boolean(anchorElNotifications)}
                disableBackdropTransition={!iOS}
                disableDiscovery={!iOS}
                onClose={() => setAnchorElNotifications(null)}
              >
                {notificationList.length > 0 && (
                  <div className="sticky-top bg-white">
                    <IconButton
                      aria-label="menu"
                      className="m-2 me-4"
                      onClick={() => setAnchorElNotifications(null)}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                    <Button
                      disabled={
                        notificationStatus === "loading" ||
                        notificationList.every((notif) => notif.isRead)
                      }
                      size="small"
                      className="py-2 mb-2 mt-2"
                      sx={{ minWidth: "0px", lineHeight: "1" }}
                      onClick={() =>
                        notificationList
                          .filter((notif) => !notif.isRead)
                          .forEach((notif) =>
                            dispatch(
                              patchCurrentNotification({
                                requestingUser: currentAuth,
                                body: {
                                  data: { _id: notif._id, isRead: true },
                                },
                              }),
                            ),
                          )
                      }
                    >
                      Mark all as read
                    </Button>
                  </div>
                )}
                {notificationList.length === 0 ? (
                  <div className="p-3">
                    <img
                      alt="No notifications"
                      src="images/no-notifications.png"
                      height="125"
                    />
                    <Typography variant="h6" align="center">
                      No notifications
                    </Typography>
                    <Typography variant="body2" align="center">
                      You're all caught up.
                    </Typography>
                  </div>
                ) : (
                  notificationList
                    .sort(
                      (a, b) =>
                        DateTime.fromISO(b.createdAt).valueOf() -
                        DateTime.fromISO(a.createdAt).valueOf(),
                    )
                    .map((notif, i) => (
                      <AppNotificationItem
                        key={notif._id}
                        notification={notif}
                        onClose={() => setAnchorElNotifications(null)}
                      />
                    ))
                )}
              </SwipeableDrawer>
            ) : (
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNotifications}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNotifications)}
                onClose={() => setAnchorElNotifications(null)}
              >
                {notificationList.length > 0 && (
                  <div className="text-center">
                    <Button
                      disabled={
                        notificationStatus === "loading" ||
                        notificationList.every((notif) => notif.isRead)
                      }
                      size="small"
                      className="py-2 mb-2"
                      sx={{ minWidth: "0px", lineHeight: "1" }}
                      onClick={() =>
                        notificationList
                          .filter((notif) => !notif.isRead)
                          .forEach((notif) =>
                            dispatch(
                              patchCurrentNotification({
                                requestingUser: currentAuth,
                                body: {
                                  data: { _id: notif._id, isRead: true },
                                },
                              }),
                            ),
                          )
                      }
                    >
                      Mark all as read
                    </Button>
                  </div>
                )}
                {notificationList.length === 0 ? (
                  <div className="p-3">
                    <img
                      alt="No notifications"
                      src="images/no-notifications.png"
                      height="125"
                    />
                    <Typography variant="h6" align="center">
                      No notifications
                    </Typography>
                    <Typography variant="body2" align="center">
                      You're all caught up.
                    </Typography>
                  </div>
                ) : (
                  notificationList
                    .sort(
                      (a, b) =>
                        DateTime.fromISO(b.createdAt).valueOf() -
                        DateTime.fromISO(a.createdAt).valueOf(),
                    )
                    .map((notif, i) => (
                      <AppNotificationItem
                        key={notif._id}
                        notification={notif}
                        onClose={() => setAnchorElNotifications(null)}
                      />
                    ))
                )}
              </Menu>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElAccount}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElAccount)}
              onClose={() => setAnchorElAccount(null)}
            >
              <Typography className="mx-3 my-1 opacity-50">
                {currentUser && currentUser.email}
              </Typography>
              <MenuItem
                component={NavLink}
                to="/account"
                className="ps-3 p-2"
                onClick={() => setAnchorElAccount(null)}
              >
                <ListItemIcon>
                  <AccountBox fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("Account")}</ListItemText>
              </MenuItem>
              <MenuItem
                component={NavLink}
                to="/sign-out"
                className="ps-3 p-2"
                onClick={() => setAnchorElAccount(null)}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t("Sign out")}</ListItemText>
              </MenuItem>
            </Menu>
            <Menu
              id="menu-lang"
              anchorEl={anchorElLang}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElLang)}
              onClose={() => setAnchorElLang(null)}
            >
              <MenuItem
                className="px-3 p-2"
                onClick={() => {
                  currentAuth &&
                    dispatch(
                      patchCurrentUser({
                        requestingUser: currentAuth,
                        body: {
                          data: { _id: currentUser._id, locale: "en" },
                        },
                      }),
                    );
                  i18n.changeLanguage("en");
                  setAnchorElLang(null);
                }}
              >
                <ListItemText>English</ListItemText>
              </MenuItem>
              <MenuItem
                className="px-3 p-2"
                onClick={() => {
                  currentAuth &&
                    dispatch(
                      patchCurrentUser({
                        requestingUser: currentAuth,
                        body: {
                          data: { _id: currentUser._id, locale: "nl" },
                        },
                      }),
                    );
                  i18n.changeLanguage("nl");
                  setAnchorElLang(null);
                }}
              >
                <ListItemText>Nederlands</ListItemText>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
// ########################################
// EXPORT
export default Header;
