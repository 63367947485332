// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Redux
import { consumersSelectors } from "../redux/reducers/consumers";
import { studiesSelectors } from "../redux/reducers/studies";
import { ticketsSelectors } from "../redux/reducers/tickets";
import { supervisionSelectors } from "../redux/reducers/supervision";
// -------------------------------------------------
// Components
import AppListItem from "../components/App_ListItem";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
// -------------------------------------------------
// Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Warning from "@mui/icons-material/Warning";
// =================================================
// FUNCTIONAL COMPONENT
const VolunteersColumnContent = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "volunteers.Volunteers_ColumnContent",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const currentUser = useSelector((state) => state.user.currentUser);
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state),
  ).filter((consumer) => consumer.deletedOn === null);
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const currentStudyId = useSelector((state) => state.consumers.currentStudyId);
  const defaultStudyId = useSelector(
    (state) => state.studies.defaultStudy && state.studies.defaultStudy._id,
  );
  const supervisionList = useSelector((state) =>
    supervisionSelectors.selectAll(state),
  );
  // -----------------------------------------------
  // Local State
  const [open, setOpen] = useState(
    currentStudyId ? { [currentStudyId]: true } : {},
  );
  const [filter, setFilter] = useState("");
  // -----------------------------------------------
  // For navigating
  const navigate = useNavigate();
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Keeps track of which sub-menus are open and closed
  useEffect(() => {
    setOpen((prevState) => {
      return { ...prevState, [currentStudyId]: true };
    });
  }, [currentStudyId]);
  // -----------------------------------------------
  // Sets the URL search query to the study and consumer identifier
  const handleSetCurrentConsumer = (studyId, consumerId) => {
    let el;
    el = document.querySelector("#scroll-top-app");
    if (el) {
      el.scrollIntoView({ block: "end" });
    }
    navigate(`/volunteers?studyId=${studyId}&consumerId=${consumerId}`, {
      replace: true,
    });
  };
  // -----------------------------------------------
  // Overdue filter
  const overdueFilter = (ticket, consumer) =>
    ticket.userId === consumer._id &&
    !ticket.isCompleted &&
    DateTime.fromISO(ticket.dateDue ? ticket.dateDue : "2199-12-31")
      .diffNow()
      .valueOf() < 0;
  // -----------------------------------------------
  // Opens and closes the various subdirectories
  const handleClick = (key) => {
    setOpen((prevState) => {
      let newState = { ...prevState };
      newState[key] = newState[key] === undefined ? true : !newState[key];
      return newState;
    });
  };
  // -----------------------------------------------
  // Open studies
  const openStudy = (key) => {
    setOpen((prevState) => {
      let newState = { ...prevState };
      newState[key] = true;
      return newState;
    });
  };
  // -----------------------------------------------
  // Extract consumer study number or userNumber
  const getConsumerNumber = (consumer) => {
    return consumer.studyEnrollmentList.length > 0
      ? consumer.studyEnrollmentList[0].userStudyNumber
      : consumer.userNumber;
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <List disablePadding>
      {consumerList.length === 0 ? (
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="my-3 text-center">
              <img
                src="images/icon-train-end.png"
                alt="No volunteers"
                className="w-50"
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">{t("No volunteers")}</Typography>
            </Grid>
          </Grid>
        </Fade>
      ) : (
        <>
          <TextField
            fullWidth
            size="small"
            name="Filter"
            type="text"
            label="Filter"
            value={filter}
            color="secondary"
            variant="filled"
            sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
            onChange={(e) => {
              studyList.forEach((study) => openStudy(study._id));
              openStudy("not-enrolled");
              setFilter(e.target.value);
            }}
          />
          {studyList
            .sort((a, b) => (a._id === defaultStudyId ? -1 : 1))
            .map((study) => (
              <React.Fragment key={study._id}>
                <AppListItem
                  divider
                  slideIn
                  onClick={() => handleClick(study._id)}
                  icon={
                    <Chip
                      label={
                        consumerList
                          .filter(
                            (consumer) =>
                              consumer.primaryRole === "participant",
                          )
                          .filter(
                            (consumer) =>
                              consumer.studyEnrollmentList.length > 0 &&
                              consumer.studyEnrollmentList[0].studyId ===
                                study._id,
                          )
                          .filter((consumer) =>
                            supervisionList.some(
                              (supervision) =>
                                supervision.userId === currentUser._id &&
                                (supervision.isAllParticipants ||
                                  supervision.userIdList.some(
                                    (uid) => uid === consumer._id,
                                  )),
                            ),
                          ).length
                      }
                      size="small"
                    />
                  }
                  iconEnd={
                    open[study._id] === undefined ? (
                      <ExpandMore />
                    ) : open[study._id] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  }
                  textPrimary={
                    <Typography noWrap variant="h5">
                      {study.acronym}
                    </Typography>
                  }
                  className="bg-light"
                />
                <Collapse in={open[study._id]} timeout="auto" unmountOnExit>
                  <ListSubheader>{t("Enrolled")}</ListSubheader>
                  <Divider />
                  <List disablePadding>
                    {consumerList
                      .filter(
                        (consumer) =>
                          consumer._id.includes(filter) ||
                          consumer.firebaseId.includes(filter) ||
                          consumer.primaryRole.includes(filter) ||
                          consumer.userNumber.includes(filter) ||
                          consumer.email.includes(filter) ||
                          (consumer.name && consumer.name.includes(filter)) ||
                          (consumer.phone && consumer.phone.includes(filter)),
                      )
                      .filter(
                        (consumer) =>
                          consumer.studyEnrollmentList.length > 0 &&
                          consumer.studyEnrollmentList[0].studyId === study._id,
                      )
                      .filter((consumer) =>
                        supervisionList.some(
                          (supervision) =>
                            supervision.userId === currentUser._id &&
                            (supervision.isAllParticipants ||
                              supervision.userIdList.some(
                                (uid) => uid === consumer._id,
                              )),
                        ),
                      )
                      .filter(
                        (consumer) => consumer.primaryRole === "participant",
                      )
                      .map((consumer) => (
                        <AppListItem
                          key={consumer._id}
                          divider
                          slideIn
                          isSelected={
                            props.currentConsumer &&
                            consumer._id === props.currentConsumer._id
                          }
                          onClick={() =>
                            handleSetCurrentConsumer(study._id, consumer._id)
                          }
                          icon={
                            <Chip
                              size="small"
                              label={
                                <Typography className="font-size-065rem">
                                  {getConsumerNumber(consumer)}
                                </Typography>
                              }
                            />
                          }
                          textPrimary={
                            <Typography className="font-size-1rem text-truncate">
                              {consumer.email}
                            </Typography>
                          }
                          textSecondary={
                            ticketList.filter((ticket) =>
                              overdueFilter(ticket, consumer),
                            ).length > 0 ? (
                              <Typography className="font-size-065rem">
                                <Warning className="font-size-085rem me-1 text-danger" />
                                {`${
                                  ticketList.filter((ticket) =>
                                    overdueFilter(ticket, consumer),
                                  ).length
                                } overdue/expired`}
                              </Typography>
                            ) : null
                          }
                        />
                      ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
        </>
      )}
      <AppListItem
        divider
        slideIn
        onClick={() => handleClick("not-enrolled")}
        icon={
          <Chip
            label={
              consumerList
                .filter((consumer) => consumer.studyEnrollmentList.length === 0)
                .filter((consumer) => consumer.primaryRole === "participant")
                .length
            }
            size="small"
          />
        }
        iconEnd={
          open["not-enrolled"] === undefined ? (
            <ExpandMore />
          ) : open["not-enrolled"] ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        }
        textPrimary={<Typography variant="h5">{t("Not enrolled")}</Typography>}
        className="bg-light"
      />
      <Collapse in={open["not-enrolled"]} timeout="auto" unmountOnExit>
        <List disablePadding>
          {consumerList
            .filter(
              (consumer) =>
                consumer._id.includes(filter) ||
                consumer.firebaseId.includes(filter) ||
                consumer.primaryRole.includes(filter) ||
                consumer.userNumber.includes(filter) ||
                consumer.email.includes(filter) ||
                (consumer.name && consumer.name.includes(filter)) ||
                (consumer.phone && consumer.phone.includes(filter)),
            )
            .filter((consumer) => consumer.studyEnrollmentList.length === 0)
            .filter((consumer) => consumer.primaryRole === "participant")
            .map((consumer) => (
              <AppListItem
                key={consumer._id}
                divider
                slideIn
                isSelected={
                  props.currentConsumer &&
                  consumer._id === props.currentConsumer._id
                }
                onClick={() =>
                  handleSetCurrentConsumer("no-study", consumer._id)
                }
                textPrimary={consumer.email}
              />
            ))}
        </List>
      </Collapse>
    </List>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersColumnContent;
