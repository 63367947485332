// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { getTicketAvailability } from "../../supportFunc/getTicketAvailability";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/tickets`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the tickets in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  currentTicket: null,
  ticketAvailabilityList: [],
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  error: null,
});
// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch all tickets from one user and study
export const fetchTicketsFromOneUserAndStudy = createAsyncThunk(
  "tickets/fetchTicketsFromOneUserAndStudy",
  async ({ requestingUser, userId, studyId }) => {
    const response = await client.get(
      `${apiurl}/user/${userId}/study/${studyId}`,
      requestingUser,
    );
    return response.data;
  },
);
// -------------------------------------------------
// API fetch all tickets from one user and study
export const fetchTicketListFromOwnedStudies = createAsyncThunk(
  "tickets/fetchTicketListFromOwnedStudies",
  async ({ requestingUser, userId }) => {
    const response = await client.get(
      `${apiurl}/study-user/${userId}`,
      requestingUser,
    );
    return response.data;
  },
);
// -------------------------------------------------
// API fetch a ticket by its id
export const fetchTicketById = createAsyncThunk(
  "tickets/fetchTicketById",
  async ({ requestingUser, ticketId, upsert }, thunk) => {
    const state = thunk.getState();
    // If not upsert and ticket id does not exist, then retun null
    if (!upsert && !state.tickets.entities[ticketId]) {
      return { ticket: null };
    }
    const response = await client.get(`${apiurl}/${ticketId}`, requestingUser);
    return response.data;
  },
);
// -------------------------------------------------
// API post ticket list array
export const postTicketList = createAsyncThunk(
  "tickets/postTicketList",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.post(apiurl, requestingUser, body);
    // Invoke event on server
    socket &&
      socket.emit("posted-ticket-list", {
        userIdList: response.data.ticketList.map((ticket) => ticket.userId),
      });
    // Return the response
    return response.data;
  },
);
// -------------------------------------------------
// API patch a ticket object
export const patchCurrentTicket = createAsyncThunk(
  "tickets/patchCurrentTicket",
  async ({ socket, requestingUser, body }) => {
    // If the ticket is a "preview" ticket, don't save it, but just return
    if (body.data.responseCollection === "previewResponses") {
      return { ticket: body.data };
    }
    // Make the call to the database
    const response = await client.patch(
      `${apiurl}/${body.data._id}`,
      requestingUser,
      body,
    );
    // Invoke event on server
    socket &&
      socket.emit("patched-current-ticket", { ticketId: body.data._id });
    // Return the response
    return response.data;
  },
);

// -------------------------------------------------
// API patch a ticket object
export const patchTicketList = createAsyncThunk(
  "tickets/patchTicketList",
  async ({ socket, requestingUser, body }) => {
    // Make the call to the database
    const response = await client.patch(`${apiurl}/`, requestingUser, body);
    // Invoke event on server
    // Note that we're re-using the 'posted-ticket-list' broadcast.
    socket &&
      socket.emit("posted-ticket-list", {
        userIdList: response.data.ticketList.map((ticket) => ticket.userId),
      });
    // Return the response
    return response.data;
  },
);
// -------------------------------------------------
// API delete a ticket object
export const deleteCurrentTicket = createAsyncThunk(
  "tickets/deleteCurrentTicket",
  async ({ socket, requestingUser, ticketId }) => {
    // Make the call to the database
    const response = await client.delete(
      `${apiurl}/${ticketId}`,
      requestingUser,
    );
    // Invoke event on server
    socket && socket.emit("deleted-current-ticket", { ticketId });
    // Return the response
    return response.data;
  },
);
// -------------------------------------------------
// API delete a list of tickets by their ID
export const deleteTicketListById = createAsyncThunk(
  "tickets/deleteTicketListById",
  async ({ socket, requestingUser, body }) => {
    // Make sure ticketIds is not empty
    if (!body.data) {
      return [];
    }
    if (body.data.length === 0) {
      return [];
    }
    // Make the call to the database
    const response = await client.delete(`${apiurl}/`, requestingUser, body);
    // Invoke event on server
    socket &&
      socket.emit("deleted-ticket-list", {
        ticketIds: response.data.ticketIds,
      });
    // Return the response
    return response.data;
  },
);
// -------------------------------------------------
// API fetch all tickets from one user and study
export const deleteTicketsFromOneUserAndStudy = createAsyncThunk(
  "tickets/deleteTicketsFromOneUserAndStudy",
  async ({ socket, requestingUser, userId, studyId }) => {
    const response = await client.delete(
      `${apiurl}/user/${userId}/study/${studyId}`,
      requestingUser,
    );
    // Invoke event on server
    socket &&
      socket.emit("deleted-ticket-list", {
        ticketIds: response.data.ticketIds,
      });
    return response.data;
  },
);
// =================================================
// DEFINE MUTATING ACTIONS
// -------------------------------------------------
export const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    resetTicketsError(state) {
      if (state.status === "failed") {
        state.status = "idle";
        state.errorMsg = null;
      }
    },
    setCurrentTicketById(state, action) {
      state.currentTicket = state.entities[action.payload.ticketId];
    },
    setCurrentTicketByObject(state, action) {
      state.currentTicket = action.payload.ticket;
    },
    setTicketAvailabilityList(state, action) {
      state.ticketAvailabilityList = action.payload.ticketAvailabilityList;
    },
    updateTicketByIdWithKeyValue(state, action) {
      let existingTicket = state.entities[action.payload.ticketId];
      if (existingTicket) {
        existingTicket[action.payload.key] = action.payload.value;
      }
      if (
        state.currentTicket &&
        state.currentTicket._id === action.payload.ticketId
      ) {
        state.currentTicket[action.payload.key] = action.payload.value;
      }
      state.ticketAvailabilityList = state.ids.map((ticketId) =>
        getTicketAvailability(state.entities[ticketId]),
      );
    },
    removeTicketListById(state, action) {
      adapter.removeMany(state, action.payload.ticketIds);
      state.currentTicket = null;
      state.ticketAvailabilityList = state.ids.map((ticketId) =>
        getTicketAvailability(state.entities[ticketId]),
      );
    },
    removeTicketById(state, action) {
      adapter.removeOne(state, action.payload.ticketId);
      if (
        state.currentTicket &&
        state.currentTicket._id === action.payload.ticketId
      ) {
        state.currentTicket = null;
      }
      state.ticketAvailabilityList = state.ids.map((ticketId) =>
        getTicketAvailability(state.entities[ticketId]),
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTicketsFromOneUserAndStudy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTicketsFromOneUserAndStudy.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticketList &&
          adapter.upsertMany(state, action.payload.ticketList);
        if (action.payload.ticketList) {
          state.ticketAvailabilityList = action.payload.ticketList.map(
            (ticket) => getTicketAvailability(ticket),
          );
        }
      })
      .addCase(fetchTicketsFromOneUserAndStudy.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchTicketListFromOwnedStudies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTicketListFromOwnedStudies.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticketList &&
          adapter.upsertMany(state, action.payload.ticketList);
        if (action.payload.ticketList) {
          state.ticketAvailabilityList = action.payload.ticketList.map(
            (ticket) => getTicketAvailability(ticket),
          );
        }
      })
      .addCase(fetchTicketListFromOwnedStudies.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(fetchTicketById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTicketById.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticket &&
          adapter.upsertOne(state, action.payload.ticket);
        if (
          action.payload.ticket &&
          state.currentTicket &&
          action.payload.ticket._id === state.currentTicket._id
        ) {
          state.currentTicket = action.payload.ticket;
        }
        if (action.payload.ticket) {
          state.ticketAvailabilityList = state.ids.map((ticketId) =>
            getTicketAvailability(state.entities[ticketId]),
          );
        }
      })
      .addCase(fetchTicketById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchCurrentTicket.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchCurrentTicket.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticket &&
          adapter.upsertOne(state, action.payload.ticket);
        state.currentTicket = action.payload.ticket;
        if (action.payload.ticket) {
          state.ticketAvailabilityList = state.ids.map((ticketId) =>
            getTicketAvailability(state.entities[ticketId]),
          );
        }
      })
      .addCase(patchCurrentTicket.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(patchTicketList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(patchTicketList.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticketList &&
          adapter.upsertMany(state, action.payload.ticketList);
        if (action.payload.ticketList) {
          state.ticketAvailabilityList = action.payload.ticketList.map(
            (ticket) => getTicketAvailability(ticket),
          );
        }
      })
      .addCase(patchTicketList.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(postTicketList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postTicketList.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticketList &&
          adapter.upsertMany(state, action.payload.ticketList);
        if (action.payload.ticketList) {
          state.ticketAvailabilityList = action.payload.ticketList.map(
            (ticket) => getTicketAvailability(ticket),
          );
        }
      })
      .addCase(postTicketList.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteCurrentTicket.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCurrentTicket.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticketId &&
          adapter.removeOne(state, action.payload.ticketId);
        if (action.payload.ticketId) {
          state.ticketAvailabilityList = state.ids.map((ticketId) =>
            getTicketAvailability(state.entities[ticketId]),
          );
        }
      })
      .addCase(deleteCurrentTicket.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteTicketsFromOneUserAndStudy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTicketsFromOneUserAndStudy.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticketIds &&
          adapter.removeMany(state, action.payload.ticketIds);
        if (action.payload.ticketIds) {
          state.ticketAvailabilityList = state.ids.map((ticketId) =>
            getTicketAvailability(state.entities[ticketId]),
          );
        }
      })
      .addCase(deleteTicketsFromOneUserAndStudy.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      })
      .addCase(deleteTicketListById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTicketListById.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.ticketIds &&
          action.payload.ticketIds.length > 0 &&
          adapter.removeMany(state, action.payload.ticketIds);
        if (action.payload.ticketIds && action.payload.ticketIds.length > 0) {
          state.ticketAvailabilityList = state.ids.map((ticketId) =>
            getTicketAvailability(state.entities[ticketId]),
          );
        }
      })
      .addCase(deleteTicketListById.rejected, (state, action) => {
        state.status = "failed";
        state.errorMsg = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const {
  resetTicketsError,
  setCurrentTicketById,
  setCurrentTicketByObject,
  setTicketAvailabilityList,
  updateTicketByIdWithKeyValue,
  removeTicketListById,
  removeTicketById,
} = ticketsSlice.actions;
// =================================================
// SELECTOR FUNCTIONS
// -------------------------------------------------
export const ticketsSelectors = adapter.getSelectors((state) => state.tickets);
// -------------------------------------------------
export const getTicketIsAboutToExpireById = (state, ticketId) => {
  const ticket = state.tickets.entities[ticketId];
  if (!ticket) {
    return "ok";
  } else if (DateTime.fromISO(ticket.dateExpire).diffNow().valueOf() < 0) {
    return "isExpired";
  } else if (
    DateTime.fromISO(ticket.dateExpire).diffNow().valueOf() <=
    2 * 60 * 1000
  ) {
    return "aboutToExpire";
  }
};
// =================================================
// EXPORT DEFAULT
export default ticketsSlice.reducer;
