// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { DateTime } from "luxon";
// -------------------------------------------------
// Support functions
import { unique } from "../supportFunc/unique";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../redux/reducers/studies";
import { surveysSelectors } from "../redux/reducers/surveys";
import { taskResponsesSelectors } from "../redux/reducers/taskResponses";
import { consumersSelectors } from "../redux/reducers/consumers";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// -------------------------------------------------
// Icons
import Done from "@mui/icons-material/Done";
import Cancel from "@mui/icons-material/Cancel";
// =================================================
// FUNCTIONAL COMPONENT
const ResultsContent = () => {
  const { t } = useTranslation("components", { keyPrefix: "Results_Content" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const isXS = useSelector((state) => state.ui.isXS);
  const isSM = useSelector((state) => state.ui.isSM);
  const studiesStatus = useSelector((state) => state.studies.status);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const taskResponsesStatus = useSelector(
    (state) => state.taskResponses.status,
  );
  const taskResponseList = useSelector((state) =>
    taskResponsesSelectors.selectAll(state),
  );
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  const surveyList = useSelector((state) => surveysSelectors.selectAll(state));
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state),
  );
  // -----------------------------------------------
  // Local state
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudies, setSelectedStudies] = useState({});
  const [selectedSurveys, setSelectedSurveys] = useState({});
  const [rows, setRows] = useState([]);
  const [columnList, setColumnList] = useState(null);
  const [dataFormat, setDataFormat] = useState("wide");
  const [dataSelect, setDataSelect] = useState("first");
  // -----------------------------------------------
  // When items are loaded, set the selected Chips
  useEffect(() => {
    if (studiesStatus === "succeeded") {
      setSelectedStudies(
        studyList.reduce((result, study) => {
          return { ...result, [study._id]: true };
        }, {}),
      );
    }
    if (surveysStatus === "succeeded") {
      setSelectedSurveys(
        surveyList.reduce((result, survey) => {
          return { ...result, [survey._id]: true };
        }, {}),
      );
    }
  }, [studiesStatus, surveysStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // -----------------------------------------------
  // Some helper functions
  const getSurveyAliasList = (entry) => {
    return Object.keys(entry);
  };
  // -----------------------------------------------
  // When the filters change, create the rows and columns
  useEffect(
    () => {
      if (
        taskResponsesStatus !== "succeeded" ||
        surveysStatus !== "succeeded" ||
        consumersStatus !== "succeeded"
      ) {
        return;
      }
      setIsLoading(true);
      // Define the long-format columns
      const newLongColumnList = [
        { field: "id", hide: true },
        { field: "alias", width: 125 },
        { field: "value", width: 125 },
        { field: "responseId", width: 125 },
        { field: "userNumber" },
        { field: "participantNumber", width: 125 },
        { field: "surveyId", width: 125 },
        { field: "surveyName", width: 125 },
        { field: "surveyVersion", width: 125 },
        { field: "inDevelopment", width: 125 },
        { field: "surveyAcronym", width: 150 },
        { field: "studyId" },
        { field: "studyName", width: 125 },
        { field: "studyAcronym", width: 125 },
        { field: "timepointId", width: 125 },
        { field: "measurementId", width: 125 },
        { field: "timepointLabel", width: 150 },
        { field: "groupId", width: 125 },
        { field: "groupLabel", width: 125 },
        { field: "entryNumber", type: "number", width: 125 },
        {
          field: "dateAvailable",
          type: "dateTime",
          valueGetter: (value) => value && DateTime.fromISO(value),
          valueFormatter: (value) =>
            value && value.startOf("minute").toISO({ suppressSeconds: true }),
          width: 150,
        },
        {
          field: "dateStarted",
          type: "dateTime",
          valueGetter: (value) => value && DateTime.fromISO(value),
          valueFormatter: (value) =>
            value && value.startOf("minute").toISO({ suppressSeconds: true }),
          width: 150,
        },
        {
          field: "dateCompleted",
          type: "dateTime",
          valueGetter: (value) => value && DateTime.fromISO(value),
          valueFormatter: (value) =>
            value && value.startOf("minute").toISO({ suppressSeconds: true }),
          width: 150,
        },
      ];
      // Collect all data from selected studies and surveys in long format
      let newLongRows = [];
      taskResponseList
        .filter(
          (res) =>
            selectedStudies[res.studyId] && selectedSurveys[res.surveyId],
        )
        .forEach((res) => {
          // Get the keys to the actual reponse data i.e., not the meta-data
          const aliasList = getSurveyAliasList(res.data);
          // For each key in the entry that starts with the acronym, create one row in 'newLongRows'
          aliasList.forEach((alias) => {
            if (!studyList || studyList.length === 0) {
              return;
            }
            if (!surveyList || surveyList.length === 0) {
              return;
            }
            let userNumber, participantNumber, thisTimepoint, timepointLabel;
            if (consumerEntities[res.userId]) {
              userNumber = consumerEntities[res.userId].userNumber;

              // userStudyNumber can be undefined when a user switches from participant to researcher
              const enrollment = consumerEntities[
                res.userId
              ].studyEnrollmentList.find(
                (enrollment) => enrollment.studyId === res.studyId,
              );
              participantNumber = enrollment?.userStudyNumber ?? "n/a";

              thisTimepoint = studyList
                .find((study) => study._id === res.studyId)
                .timepointList.find(
                  (timepoint) => timepoint._id === res.timepointId,
                );
              timepointLabel =
                thisTimepoint && thisTimepoint.label
                  ? thisTimepoint.label
                  : "n/a";
            } else {
              userNumber = "anonymous";
              participantNumber = "anonymous";
              timepointLabel = "n/a";
            }
            const append = {
              id: uuid(),
              alias: alias,
              value: res.data[alias],
              responseId: res._id,
              userNumber,
              participantNumber,
              surveyId: res.surveyId,
              surveyName: surveyList.find(
                (survey) => survey._id === res.surveyId,
              ).name.official,
              surveyVersion: surveyList.find(
                (survey) => survey._id === res.surveyId,
              ).version,
              inDevelopment: res.inDevelopment,
              surveyAcronym: surveyList.find(
                (survey) => survey._id === res.surveyId,
              ).acronym,
              studyId: res.studyId,
              studyName: studyList.find((study) => study._id === res.studyId)
                .name,
              studyAcronym: studyList.find((study) => study._id === res.studyId)
                .acronym,
              timepointId: res.timepointId,
              timepointLabel,
              measurementId: res.measurementId,
              groupId: res.groupId,
              groupLabel:
                res.groupId &&
                studyList
                  .find((study) => study._id === res.studyId)
                  .groupList.find((group) => group._id === res.groupId).label,
              entryNumber: res.entryNumber,
              dateAvailable: res.dateAvailable,
              dateDue: res.dateDue,
              dateExpire: res.dateExpire,
              dateStarted: res.dateStarted,
              dateCompleted: res.dateCompleted,
            };
            newLongRows = [...newLongRows, { ...append }];
          });
        });
      // Set the column and row data for the data table if long format is requested
      if (dataFormat === "long") {
        setColumnList(newLongColumnList);
        setRows(newLongRows);
        setIsLoading(false);
        return;
      }
      // Convert long to wide format
      const aliasList = unique(newLongRows.map((row) => row.alias));
      const surveyIds = unique(newLongRows.map((row) => row.surveyId));
      const userNumberList = unique(newLongRows.map((row) => row.userNumber));
      const studyIds = unique(newLongRows.map((row) => row.studyId));
      const timepointIds = unique(newLongRows.map((row) => row.timepointId));
      let newWideColumnList = [
        { field: "id", hide: true },
        { field: "userNumber" },
        { field: "participantNumber", width: 125 },
        { field: "studyId", width: 125 },
        { field: "studyName", width: 125 },
        { field: "studyAcronym", width: 125 },
        { field: "timepointId", width: 125 },
        { field: "timepointLabel", width: 150 },
      ];
      newWideColumnList = surveyIds.reduce((res, surveyId) => {
        const acronym = surveyList.find(
          (survey) => survey._id === surveyId,
        ).acronym;
        const metaColumnList = [
          {
            field: `${acronym}_measurementId`,
            origField: "measurementId",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_surveyId`,
            origField: "surveyId",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_surveyName`,
            origField: "surveyName",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_surveyVersion`,
            origField: "surveyVersion",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_inDevelopment`,
            origField: "inDevelopment",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_surveyAcronym`,
            origField: "surveyAcronym",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_responseId`,
            origField: "responseId",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_groupId`,
            origField: "groupId",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_groupLabel`,
            origField: "groupLabel",
            width: 150,
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_entryNumber`,
            origField: "entryNumber",
            width: 150,
            type: "number",
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_dateAvailable`,
            origField: "dateAvailable",
            width: 150,
            type: "dateTime",
            valueGetter: (value) => value && DateTime.fromISO(value),
            valueFormatter: (value) =>
              value && value.startOf("minute").toISO({ suppressSeconds: true }),
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_dateStarted`,
            origField: "dateStarted",
            width: 150,
            type: "dateTime",
            valueGetter: (value) => value && DateTime.fromISO(value),
            valueFormatter: (value) =>
              value && value.startOf("minute").toISO({ suppressSeconds: true }),
            surveyId,
            acronym,
          },
          {
            field: `${acronym}_dateCompleted`,
            origField: "dateCompleted",
            width: 150,
            type: "dateTime",
            valueGetter: (value) => value && DateTime.fromISO(value),
            valueFormatter: (value) =>
              value && value.startOf("minute").toISO({ suppressSeconds: true }),
            surveyId,
            acronym,
          },
        ];
        const dataColumnList = aliasList
          .filter((alias) => alias.startsWith(acronym))
          .map((alias) => {
            return { field: alias, surveyId, acronym };
          });
        return [...res, ...metaColumnList, ...dataColumnList];
      }, newWideColumnList);
      // Now for each unique user, study and timepoint, extract the data
      let newWideRows = [];
      userNumberList.forEach((uId) => {
        studyIds.forEach((sId) => {
          timepointIds.forEach((tId) => {
            const filteredLongRows = newLongRows
              .filter(
                (entry) =>
                  entry.userNumber === uId &&
                  entry.studyId === sId &&
                  entry.timepointId === tId,
              )
              .sort((a, b) => (a.dateAvailable < b.dateAvailable ? -1 : 1));
            if (filteredLongRows.length === 0) {
              return;
            }
            let append = {
              id: uuid(),
              userNumber: uId,
              participantNumber: filteredLongRows[0].participantNumber,
              studyId: sId,
              studyName: filteredLongRows[0].studyName,
              studyAcronym: filteredLongRows[0].studyAcronym,
              timepointId: tId,
              timepointLabel: filteredLongRows[0].timepointLabel,
            };
            append = newWideColumnList.reduce((res, item) => {
              if (!item.surveyId) {
                return res;
              }
              const values = item.origField
                ? filteredLongRows.filter(
                    (entry) => entry.surveyId === item.surveyId,
                  )
                : filteredLongRows.filter(
                    (entry) =>
                      entry.surveyId === item.surveyId &&
                      entry.alias === item.field,
                  );
              const idx = dataSelect === "first" ? 0 : values.length - 1;
              const newItem =
                values.length === 0
                  ? { [item.field]: null }
                  : item.origField
                    ? {
                        [item.field]: values[idx][item.origField],
                      }
                    : {
                        [item.field]: values[idx].value,
                      };
              return { ...res, ...newItem };
            }, append);
            newWideRows = [...newWideRows, append];
          });
        });
      });
      // Set the column and row data for the data table
      setColumnList(newWideColumnList);
      setRows(newWideRows);
      setIsLoading(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      consumerEntities,
      consumersStatus,
      studyList,
      surveyList,
      taskResponseList,
      surveysStatus,
      taskResponsesStatus,
      selectedStudies,
      selectedSurveys,
      dataFormat,
      dataSelect,
    ],
  );
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      {!isXS && !isSM && (
        <Grid item xs={12}>
          <Typography variant="h2" className="my-2">
            {t("Results")}
          </Typography>
        </Grid>
      )}
      <Grid container className={isXS || isSM ? "mt-2" : "pe-2"}>
        <Grid item xs={12} className={isXS || isSM ? "mb-3 px-2" : "mb-3"}>
          <Paper className="p-3 pt-2">
            <Typography variant="h4">{t("Filter studies")}</Typography>
            {studyList.map((study) => (
              <Chip
                key={study._id}
                label={study.acronym}
                variant={selectedStudies[study._id] ? "filled" : "outlined"}
                deleteIcon={selectedStudies[study._id] ? <Done /> : <Cancel />}
                className="me-2 mt-2 border"
                onClick={() =>
                  setSelectedStudies((prevState) => {
                    return { ...prevState, [study._id]: !prevState[study._id] };
                  })
                }
                onDelete={() =>
                  setSelectedStudies((prevState) => {
                    return { ...prevState, [study._id]: !prevState[study._id] };
                  })
                }
              />
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} className={isXS || isSM ? "mb-3 px-2" : "mb-3"}>
          <Paper className="p-3 pt-2">
            <Typography variant="h4">{t("Filter surveys")}</Typography>
            {surveyList
              .filter((survey) =>
                studyList
                  .filter((study) => selectedStudies[study._id])
                  .some(
                    (study) =>
                      study.timepointList &&
                      study.timepointList.some(
                        (timepoint) =>
                          timepoint.measurementList &&
                          timepoint.measurementList.some(
                            (s) => s.surveyId === survey._id,
                          ),
                      ),
                  ),
              )
              .filter((survey) =>
                taskResponseList.some((res) => res.surveyId === survey._id),
              )
              .map((survey) => (
                <Chip
                  key={survey._id}
                  label={survey.name.official}
                  variant={selectedSurveys[survey._id] ? "filled" : "outlined"}
                  deleteIcon={
                    selectedSurveys[survey._id] ? <Done /> : <Cancel />
                  }
                  className="me-2 mt-2 border"
                  onClick={() =>
                    setSelectedSurveys((prevState) => {
                      return {
                        ...prevState,
                        [survey._id]: !prevState[survey._id],
                      };
                    })
                  }
                  onDelete={() =>
                    setSelectedSurveys((prevState) => {
                      return {
                        ...prevState,
                        [survey._id]: !prevState[survey._id],
                      };
                    })
                  }
                />
              ))}
          </Paper>
        </Grid>
        <Grid item xs={12} className={isXS || isSM ? "mb-3 px-2" : "mb-3"}>
          <Paper className="px-3 pb-1 pt-2">
            <Typography variant="h4" className="mb-1">
              {t("Options")}
            </Typography>
            <List disablePadding>
              <ListItem divider={dataFormat === "wide"}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm="auto">
                    <ListItemText
                      secondary="DATA FORMAT"
                      className="min-width-125px"
                    />
                  </Grid>
                  <Grid item xs={9} sm="auto">
                    <ToggleButtonGroup
                      value={dataFormat}
                      exclusive
                      size="small"
                      onChange={(e, newFormat) =>
                        newFormat && setDataFormat(newFormat)
                      }
                    >
                      <ToggleButton value="long">{t("Long")}</ToggleButton>
                      <ToggleButton value="wide">{t("Wide")}</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </ListItem>
              {dataFormat === "wide" && (
                <ListItem>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm="auto">
                      <ListItemText
                        secondary="SELECT"
                        className="min-width-125px"
                      />
                    </Grid>
                    <Grid item xs={9} sm="auto">
                      <ToggleButtonGroup
                        value={dataSelect}
                        exclusive
                        size="small"
                        onChange={(e, newSelect) =>
                          newSelect && setDataSelect(newSelect)
                        }
                      >
                        <ToggleButton value="first">
                          First timepoint entry
                        </ToggleButton>
                        <ToggleButton value="last">
                          Last timepoint entry
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} className={isXS || isSM ? "mb-3 px-2" : "mb-3"}>
          <Paper className="p-0">
            {isLoading && (
              <LinearProgress
                color="secondary"
                className="rounded lin-prog-margin-4px"
              />
            )}
            {columnList && columnList.length > 0 && (
              <DataGrid
                rows={rows}
                columns={columnList}
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection={false}
                autoHeight={true}
                density="compact"
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default ResultsContent;
