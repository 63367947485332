// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Context
import { useAuth } from "../../contexts/auth";
import { useSocket } from "../../contexts/socket";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { studiesSelectors } from "../../redux/reducers/studies";
import { consumersSelectors } from "../../redux/reducers/consumers";
import {
  postTicketList,
  deleteTicketsFromOneUserAndStudy,
} from "../../redux/reducers/tickets";
import { postNotificationList } from "../../redux/reducers/notifications";
// -------------------------------------------------
// Component elements
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
// -------------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
// -------------------------------------------------
// Helper functions
import { unshiftUserStudyEnrollment } from "../../supportFunc/unshiftUserStudyEnrollment";
import { getTicketsForUserAndTimepoint } from "../../supportFunc/getTicketsForUserAndTimepoint";
// =================================================
// FUNCTIONAL COMPONENT
const VolunteersInvite = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "volunteers.Volunteers_Invite",
  });
  // =================================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Location
  const navigate = useNavigate();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id,
  );
  const isXS = useSelector((state) => state.ui.isXS);
  const consumersStatus = useSelector((state) => state.consumers.status);
  const studyEntities = useSelector((state) =>
    studiesSelectors.selectEntities(state),
  );
  const consumerList = useSelector((state) =>
    consumersSelectors.selectAll(state),
  );
  const currentStudyId = useSelector((state) => state.consumers.currentStudyId);
  const currentStudy = useSelector((state) =>
    studiesSelectors.selectById(state, currentStudyId),
  );
  // -----------------------------------------------
  // Local State
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [forceEnroll, setForceEnroll] = useState(false);
  const [ticketsToCreate, setTicketsToCreate] = useState("new");
  const [supervisorList, setSupervisorList] = useState(
    currentStudy &&
      currentStudy.userIdList &&
      currentStudy.userIdList.map((investigatorId) => investigatorId),
  );
  // -----------------------------------------------
  // Data table
  const columnList = [
    { field: "userNumber", headerName: t("PID"), width: 75, type: "number" },
    { field: "email", headerName: t("Email"), width: 200 },
    {
      field: "createdAt",
      headerName: t("Created on"),
      width: 100,
      type: "dateTime",
      valueFormatter: (value) =>
        DateTime.fromISO(value).toFormat("dd LLL yyyy"),
    },
    {
      field: "lastSignInOn",
      headerName: t("Last sign-in"),
      width: 100,
      type: "dateTime",
      valueFormatter: (value) =>
        DateTime.fromISO(value).toFormat("dd LLL yyyy"),
    },
    { field: "currentEnrollment", headerName: t("Enrolled in"), width: 200 },
    {
      field: "userStudyNumber",
      headerName: "Study PID",
      width: 100,
      type: "number",
    },
  ];
  // =================================================
  // FUNCTIONS
  // -----------------------------------------------
  // Parses the RegEx
  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };
  // -----------------------------------------------
  // Sets the search text as a RegEx
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };
  // -----------------------------------------------
  const handlePostInviteList = async () => {
    const notificationList = selectionModel.map((consumerId) => {
      return {
        _id: uuid(),
        expireAt: DateTime.now()
          .startOf("minute")
          .plus({ months: 1 })
          .toISO({ suppressSeconds: true }),
        userId: consumerId,
        actorId: currentUserId,
        entityId: currentStudyId,
        type: "study-invite",
        data: {
          studyAcronym: currentStudy.acronym,
          ticketsToCreate,
        },
        actions: [
          {
            label: { en: "enroll", nl: "inschrijven", de: "einschreiben" },
            actionURL: "@handleEnroll",
          },
          ...(currentStudy.url
            ? [
                {
                  label: {
                    en: "learn more",
                    nl: "lees meer",
                    de: "mehr lesen",
                  },
                  variant: "outlined",
                  color: "inherit",
                  actionURL: currentStudy.url,
                },
              ]
            : []),
        ],
      };
    });
    // Post notifications to server
    dispatch(
      postNotificationList({
        socket,
        requestingUser: currentAuth,
        body: { data: notificationList },
      }),
    );
    dispatch(toggleSecDrawer({ isOpen: false }));
    navigate("/volunteers", { replace: true });
  };
  // -----------------------------------------------
  // Unshifts the studyEnrollmentList field of the new recruits thereby enrolling them
  const handleEnrollRecruits = async () => {
    let newTicketList = [];
    let appendTickets;
    selectionModel &&
      selectionModel.forEach((consumerId) => {
        const currentEnrollment = consumerList.find(
          (consumer) => consumer._id === consumerId,
        ).studyEnrollmentList[0];
        if (currentEnrollment) {
          // Delete any existing tickets
          dispatch(
            deleteTicketsFromOneUserAndStudy({
              socket,
              requestingUser: currentAuth,
              userId: consumerId,
              studyId: currentEnrollment.studyId,
            }),
          );
        }
        // Add the study enrollment to the user object
        const newUser = unshiftUserStudyEnrollment(
          consumerList.find((consumer) => consumer._id === consumerId),
          currentStudy,
        );
        props.handlePatchCurrentConsumer(newUser);
        // Create new ticket list
        for (
          var i = 0;
          i < newUser.studyEnrollmentList[0].timepointAssignmentList.length;
          i++
        ) {
          if (
            newUser.studyEnrollmentList[0].timepointAssignmentList[i].startDate
          ) {
            appendTickets = getTicketsForUserAndTimepoint(
              newUser,
              currentStudy,
              newUser.studyEnrollmentList[0].timepointAssignmentList[i]
                .timepointId,
              "taskResponses",
            );
            if (appendTickets) {
              newTicketList = [...newTicketList, ...appendTickets];
            }
          }
        }
      });
    newTicketList.length > 0 &&
      dispatch(
        postTicketList({
          socket,
          requestingUser: currentAuth,
          body: {
            data: newTicketList,
            meta: { userId: currentUserId, ticketsToCreate },
          },
        }),
      );
    navigate("/volunteers", { replace: true });
  };
  // -----------------------------------------------
  useEffect(() => {
    setSupervisorList(
      currentStudy &&
        currentStudy.userIdList &&
        currentStudy.userIdList.map((investigatorId) => investigatorId),
    );
    setRows(
      consumerList
        .filter((consumer) => consumer.consent.includes("agreeToContact"))
        .filter((consumer) => consumer.primaryRole === "participant")
        .filter(
          (consumer) =>
            consumer.studyEnrollmentList.length === 0 ||
            consumer.studyEnrollmentList[0].studyId !== currentStudyId,
        )
        .map((consumer) => {
          return {
            id: consumer._id,
            userNumber: consumer.userNumber,
            email: consumer.email,
            createdAt: consumer.createdAt,
            lastSignInOn: consumer.lastSignInOn,
            currentEnrollment:
              consumer.studyEnrollmentList.length > 0 &&
              studyEntities[consumer.studyEnrollmentList[0].studyId]
                ? studyEntities[consumer.studyEnrollmentList[0].studyId].name
                : consumer.studyEnrollmentList.length > 0
                  ? t("Someone else's study")
                  : t("Not enrolled"),
            userStudyNumber:
              consumer.studyEnrollmentList.length > 0
                ? consumer.studyEnrollmentList[0].userStudyNumber
                : "",
          };
        }),
    );
    return () => {
      setSearchText("");
      setRows([]);
      setSelectionModel([]);
    };
  }, [consumersStatus, currentStudyId]); // eslint-disable-line react-hooks/exhaustive-deps
  // =================================================
  // SUB COMPONENTS
  // -----------------------------------------------
  const QuickSearchToolbar = (props) => {
    return (
      <GridToolbarContainer className="m-2">
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <TopDrawer
      id="invite-volunteers"
      title={isXS ? t("Invite") : t("Invite volunteers")}
      buttons={
        <Button
          disabled={consumersStatus === "loading"}
          color="inherit"
          startIcon={<Save />}
          className="m-2"
          onClick={forceEnroll ? handleEnrollRecruits : handlePostInviteList}
        >
          {consumersStatus === "loading" ? (
            <CircularProgress size="1.5rem" className="text-light" />
          ) : (
            t("Save")
          )}
        </Button>
      }
    >
      <Grid container direction="column" className="h-100">
        <Grid item>
          <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-92vw"}>
            <Typography variant="h4" className="mb-0">
              Supervised by investigators
            </Typography>
            {supervisorList &&
              supervisorList.map((investigatorId) => (
                <Chip
                  key={investigatorId}
                  label={
                    consumerList.find(
                      (consumer) => consumer._id === investigatorId,
                    ).name
                      ? `${
                          consumerList.find(
                            (consumer) => consumer._id === investigatorId,
                          ).name
                        } <${
                          consumerList.find(
                            (consumer) => consumer._id === investigatorId,
                          ).email
                        }>`
                      : `<${
                          consumerList.find(
                            (consumer) => consumer._id === investigatorId,
                          ).email
                        }>`
                  }
                  size="small"
                  className="me-2 mt-2"
                />
              ))}
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            className={isXS ? "mx-2 mb-2 p-3" : "mx-2 mb-2 p-3 fix-width-92vw"}
          >
            <Typography variant="h4" className="mb-0">
              {t("New recruits")}
            </Typography>
            {selectionModel.map((consumerId) => (
              <Chip
                key={consumerId}
                label={
                  consumerList.find((consumer) => consumer._id === consumerId)
                    .userNumber
                }
                size="small"
                className="me-2 mt-2"
              />
            ))}
            {selectionModel.length > 0 && (
              <>
                <Divider className="mt-3 mb-2" />
                <Typography variant="h4">{t("Options")}</Typography>
              </>
            )}
            {selectionModel.length > 0 && (
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={forceEnroll}
                      color="secondary"
                      onChange={(e) => setForceEnroll(e.target.checked)}
                    />
                  }
                  label={t("Force enrollment")}
                  className="m-0 mt-2"
                />
              </Box>
            )}
            {selectionModel.some((consumerId) =>
              consumerList
                .find((consumer) => consumer._id === consumerId)
                .studyEnrollmentList.some(
                  (study) => study.studyId === currentStudyId,
                ),
            ) && (
              <Alert severity="info" className="mt-2">
                <AlertTitle>
                  {t(
                    "Some new recruits have already participated in this study.",
                  )}
                </AlertTitle>
                {t(
                  "Do you want to re-create all tickets so the participant is enrolled at the very start of the study? Or do you only want to create tickets that have not been completed previously so the participant continues the study where they left off?",
                )}
                <FormControl className="d-block">
                  <RadioGroup
                    value={ticketsToCreate}
                    onChange={(e) => setTicketsToCreate(e.target.value)}
                  >
                    <FormControlLabel
                      value="new"
                      label={t("Re-create all tickets")}
                      control={<Radio color="secondary" />}
                    />
                    <FormControlLabel
                      value="continue"
                      label={t(
                        "Only create tickets that have not been completed previously",
                      )}
                      control={<Radio color="secondary" />}
                    />
                  </RadioGroup>
                </FormControl>
              </Alert>
            )}
          </Paper>
        </Grid>
        <Grid item className="pb-2 flex-grow-1">
          <DataGrid
            checkboxSelection
            rows={rows}
            columns={columnList}
            rowSelectionModel={selectionModel}
            components={{
              Toolbar: QuickSearchToolbar,
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (e) => requestSearch(e.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            className="bg-white mx-2 h-100"
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
          />
        </Grid>
      </Grid>
    </TopDrawer>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersInvite;
