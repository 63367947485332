// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
// -------------------------------------------------
// Basic elements
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
// -------------------------------------------------
// Transitions
import Slide from "@mui/material/Slide";
// =================================================
// FUNCTIONAL COMPONENT
const AppListItem = (props) => {
  const listItemContent = (
    <>
      <ListItemIcon sx={{ minWidth: "8px" }} className="me-2">
        {props.icon}
      </ListItemIcon>
      <ListItemText
        primary={props.textPrimary}
        secondary={props.textSecondary}
        primaryTypographyProps={{
          fontWeight: 500,
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        secondaryTypographyProps={{
          variant: "body2",
          fontWeight: 400,
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      />
      {props.iconEnd}
    </>
  );

  return (
    <Slide
      direction="right"
      appear={false}
      in={props.slideIn}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <ListItem
          disabled={props.disabled}
          disablePadding
          className={`${props.isSelected ? "bg-list-item-selected" : ""} ${props.className}`}>
          {props.noButton ? (
            listItemContent
          ) : (
            <ListItemButton disabled={props.disabled} onClick={props.onClick}>
              {listItemContent}
            </ListItemButton>
          )}
        </ListItem>
        {props.divider && <Divider sx={{ color: "var(--color-light-grey)" }} />}
      </div>
    </Slide>
  );
};
// =================================================
// EXPORT COMPONENT
export default AppListItem;