// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Helper functions
import { str2color } from "../../supportFunc/str2color";
// -------------------------------------------------
// Redux
import { consumersSelectors } from "../../redux/reducers/consumers";
import { supervisionSelectors } from "../../redux/reducers/supervision";
import {
  fetchWebpageList,
  webpageSelectors,
} from "../../redux/reducers/webpages";
// -------------------------------------------------
import { useAuth } from "../../contexts/auth";
import { useSocket } from "../../contexts/socket";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesEditInfo = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_EditInfo",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const { currentAuth } = useAuth();
  const { socket } = useSocket();

  const isXS = useSelector((state) => state.ui.isXS);
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state),
  );
  const supervisionEntities = useSelector((state) =>
    supervisionSelectors.selectEntities(state),
  );

  const webpagesList = useSelector(webpageSelectors.selectAll);
  const webpagesStatus = useSelector((state) => state.webpages.status);

  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Get the list of URLs of all webpages
  useEffect(() => {
    if (webpagesStatus === "idle" || webpagesStatus === "partial") {
      dispatch(fetchWebpageList({ socket, requestingUser: currentAuth }));
    }
  }, [webpagesStatus, dispatch, currentAuth, socket]);

  const urlList = useMemo(() => {
    return webpagesList.map((page) => `/${page.url}`);
  }, [webpagesList]);

  // -----------------------------------------------
  const stringAvatar = (id, name) => {
    const abbrev = name.trim().split(" ");
    return {
      sx: {
        bgcolor: str2color(id),
      },
      children:
        abbrev.length === 1
          ? `${name[0].toUpperCase()}`
          : `${name.split(" ")[0][0].toUpperCase()}${name
              .split(" ")[1][0]
              .toUpperCase()}`,
    };
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (e) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      if (e.target.name === "acronym") {
        newState[e.target.name] = e.target.value
          .toUpperCase()
          .replace(/\W/g, "_");
      } else {
        newState[e.target.name] = e.target.value;
      }
      return newState;
    });
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetUserIdList = (e) => {
    const {
      target: { value },
    } = e;
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      let newUserIdList = typeof value === "string" ? value.split(",") : value;
      if (newUserIdList.length === 0) {
        return prevState;
      }
      newState.userIdList = newUserIdList;
      return newState;
    });
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Paper className={isXS ? "m-2 p-3" : "m-2 p-3 fix-width-625px"}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline" className="font-weight-bold">
              {t("Study name")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              type="text"
              name="name"
              value={props.obj.name ? props.obj.name : ""}
              color="secondary"
              variant="filled"
              className="bg-white answer-text-green mt-1 mb-3"
              onChange={handleSetObj}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" className="font-weight-bold">
              {t("Acronym")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              hiddenLabel
              size="small"
              type="text"
              name="acronym"
              value={props.obj.acronym ? props.obj.acronym : ""}
              color="secondary"
              variant="filled"
              className="bg-white answer-text-green mt-1 mb-3"
              onChange={handleSetObj}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" className="font-weight-bold">
              {t("URL")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              fullWidth
              hiddenLabel
              size="small"
              options={urlList} // Provide options if needed
              value={props.obj.url ? props.obj.url : ""}
              onChange={(event, newValue) => {
                const syntheticEvent = {
                  target: {
                    name: "url",
                    value: newValue,
                  },
                };
                handleSetObj(syntheticEvent);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="URL"
                  label="relative URL (e.g. /my-study)"
                  color="secondary"
                  variant="filled"
                  className="bg-white answer-text-green mt-1 mb-3"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" className="font-weight-bold">
              {t("Investigators")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <Select
                hiddenLabel
                multiple
                value={props.obj.userIdList}
                onChange={handleSetUserIdList}
                input={<FilledInput color="secondary" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map(
                      (investigatorId) =>
                        props.investigators.find(
                          (i) => i._id === investigatorId,
                        ) && (
                          <Chip
                            key={investigatorId}
                            label={
                              props.investigators.find(
                                (i) => i._id === investigatorId,
                              ).email
                            }
                          />
                        ),
                    )}
                  </Box>
                )}
              >
                {props.investigators.map((investigator) => (
                  <MenuItem key={investigator._id} value={investigator._id}>
                    {investigator && investigator.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <div className={isXS ? "" : "fix-width-625px"}>
        <Typography variant="h3" className="ms-2 mb-2">
          Investigators
        </Typography>
        <Grid container className="px-2" spacing={2} alignItems="stretch">
          {props.obj.userIdList.map((userId) => (
            <Grid item xs={6} sm={4}>
              <Grid
                container
                className="bg-white p-2 h-100"
                sx={{
                  borderRadius: "4px",
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
              >
                <Grid item className="mx-auto">
                  <Avatar
                    {...stringAvatar(
                      userId,
                      consumerEntities[userId] && consumerEntities[userId].name
                        ? consumerEntities[userId].name
                        : consumerEntities[userId].email,
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    className="text-truncate"
                  >
                    {consumerEntities[userId].email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    align="center"
                    className="text-truncate"
                  >
                    {Object.values(supervisionEntities).filter(
                      (supervision) => supervision.userId === userId,
                    ).length > 0
                      ? Object.values(supervisionEntities)
                          .filter(
                            (supervision) =>
                              supervision.userId === userId &&
                              supervision.studyId === props.currentStudyId,
                          )
                          .map((supervision) => supervision.role)
                      : "no role"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesEditInfo;
