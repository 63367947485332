// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Contexts
import { useAuth } from "../../contexts/auth";
import { useSocket } from "../../contexts/socket";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { patchDefaultStudyId } from "../../redux/reducers/studies";
import { consumersSelectors } from "../../redux/reducers/consumers";
// -------------------------------------------------
// Component elements
import StudiesEditInfo from "./Studies_EditInfo";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// -------------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const StudiesContentInfo = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_ContentInfo",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const studiesStatus = useSelector((state) => state.studies.status);
  const defaultStudyId = useSelector((state) =>
    state.studies.defaultStudy ? state.studies.defaultStudy._id : null,
  );
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state),
  );
  const currentRole = useSelector(
    (state) => state.user.currentUser && state.user.currentUser.primaryRole,
  );
  // -----------------------------------------------
  // Survey information keys
  const infoKeys = [
    { key: "name", label: t("Study name") },
    { key: "acronym", label: t("Acronym") },
    { key: "createdAt", label: t("Created"), isDate: true },
    { key: "url", label: t("URL") },
  ];
  // -----------------------------------------------
  // Local state
  const [info, setInfo] = useState(props.info);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles setting the default study id
  const handleEditSurveyInfo = () => {
    dispatch(toggleSecDrawer({ isOpen: true, id: "studies_content-info" }));
  };
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey info
  const handlePatchDefaultStudyId = () => {
    dispatch(
      patchDefaultStudyId({
        socket,
        requestingUser: currentAuth,
        body: {
          data: { defaultStudyId: props.currentStudyId },
        },
      }),
    );
  };

  const handleSetInDevelopment = (event) => {
    const { checked } = event.target;
    props.handleMutateCurrentStudy("info", {
      ...props.info,
      inDevelopment: checked,
    });
  };

  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setInfo(props.info);
    }
  }, [props.info, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      <TopDrawer
        id={"studies_content-info"}
        title={t("Study information")}
        buttons={
          <Button
            disabled={
              studiesStatus === "loading" || !info.name || !info.acronym
            }
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={() => props.handleMutateCurrentStudy("info", info)}
          >
            {studiesStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              t("Save")
            )}
          </Button>
        }
      >
        <StudiesEditInfo
          currentStudyId={props.currentStudyId}
          obj={info}
          setObj={setInfo}
          investigators={Object.values(consumerEntities).filter(
            (consumer) =>
              consumer.primaryRole && consumer.primaryRole !== "participant",
          )}
        />
      </TopDrawer>
      {/* SECTION SHOWING CURRENT INFO */}
      <Card className="mb-2">
        <List disablePadding className="px-3 pt-2">
          <ListItem
            disablePadding
            secondaryAction={
              props.canBeEdited && (
                <Tooltip arrow title={t("Edit")} placement="top">
                  <IconButton
                    disabled={studiesStatus === "loading"}
                    edge="end"
                    onClick={handleEditSurveyInfo}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <ListItemText
              primary={
                <Typography variant="h3">{t("Study information")}</Typography>
              }
            />
          </ListItem>
        </List>
        <List disablePadding className="px-3">
          {currentRole && currentRole === "superuser" && (
            <ListItem divider>
              <Grid container alignItems="center">
                <Grid item xs={12} sm="auto">
                  <ListItemText
                    secondary="DEFAULT STUDY"
                    className="min-width-125px"
                  />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <FormControlLabel
                    disabled={props.currentStudyId === defaultStudyId}
                    className="m-0"
                    control={
                      <Switch
                        checked={props.currentStudyId === defaultStudyId}
                        color="secondary"
                        onChange={handlePatchDefaultStudyId}
                      />
                    }
                    label={
                      props.currentStudyId === defaultStudyId ? (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ fontSize: "0.8125rem" }}
                        >
                          {t("Yes")}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ fontSize: "0.8125rem" }}
                        >
                          {t("No")}
                        </Typography>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          )}
          <ListItem divider>
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText
                  secondary={t("In development").toUpperCase()}
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <FormControlLabel
                  className="m-0"
                  control={
                    <Switch
                      checked={info.inDevelopment === true}
                      color="secondary"
                      onChange={handleSetInDevelopment}
                    />
                  }
                  label={
                    info.inDevelopment === true ? (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        {t(
                          "Yes (Surveys will not locked when answers are submitted.)",
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                      >
                        {t("No")}
                      </Typography>
                    )
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
          {infoKeys.map((item) => (
            <ListItem divider key={item.key}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm="auto">
                  <ListItemText
                    secondary={item.label.toUpperCase()}
                    className="min-width-125px"
                  />
                </Grid>
                <Grid item xs={9} sm="auto">
                  <Typography
                    paragraph={false}
                    className="d-inline"
                    variant={"caption"}
                    color={"textSecondary"}
                    sx={{ fontSize: "0.8125rem" }}
                  >
                    {!props.info[item.key]
                      ? t("no KEY provided", { key: item.key })
                      : item.isDate
                        ? DateTime.fromISO(
                            props.info[item.key],
                          ).toRelativeCalendar()
                        : props.info[item.key]}
                  </Typography>
                  {item.isDate && (
                    <Typography
                      paragraph={false}
                      variant="caption"
                      color="GrayText"
                      className="ps-3"
                    >
                      {DateTime.fromISO(props.info[item.key]).toFormat(
                        "ccc d LLL yyyy HH:mm",
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </ListItem>
          ))}
          <ListItem className="pb-0">
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText
                  secondary="PARTICIPANTS"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <GroupIcon /> {props.numberOfEnrolledConsumers}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container alignItems="center">
              <Grid item xs={12} sm="auto">
                <ListItemText
                  secondary="RESPONSES"
                  className="min-width-125px"
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <AssignmentIcon /> {props.numberOfStudyResponses}
              </Grid>
              <Grid item xs textAlign="right">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ fontSize: "0.8125rem" }}
                >
                  {(props.numberOfEnrolledConsumers === -1 ||
                    props.numberOfStudyResponses === -1) &&
                    t("Groups and timepoints can no longer be edited.")}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Card>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesContentInfo;
