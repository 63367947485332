// =================================================
// IMPORT
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
// =================================================
// IMPORT API
import { client, rooturl } from "../../api-routes/client";
const apiurl = `${rooturl}/public/webpages`;
// -------------------------------------------------
// Use 'createEntityAdapter' to store the response data in a normalized state
const adapter = createEntityAdapter({
  selectId: (a) => a._id,
});
// =================================================
// INIT STATE
const initialState = adapter.getInitialState({
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed',
  currentWebpage: null,
  error: null,
});

// =================================================
// ASYNC API ACTIONS
// -------------------------------------------------
// API fetch the list of all webpages
export const fetchWebpageList = createAsyncThunk(
  "publicWebpages/fetchWebpageList",
  async () => {
    const response = await client.get(apiurl);
    return response.data;
  }
);

// API fetch the list of all webpages with a specific type
export const fetchWebpageListByType = createAsyncThunk(
  "publicWebpages/fetchWebpageListByType",
  async (type) => {
    const response = await client.get(`${apiurl}?type=${type}`);
    return response.data;
  }
);

// API fetch a webpage object by its id
export const fetchWebpageByUrl = createAsyncThunk(
  "publicWebpages/fetchWebpageByUrl",
  async (webpageUrl) => {
    const response = await client.get(`${apiurl}/${webpageUrl}`);
    return response.data;
  }
);

// =================================================
// DEFINE MUTATING ACTIONS
export const publicWebpagesSlice = createSlice({
  name: "publicWebpages",
  initialState,
  reducers: {
    setCurrentWebpageId(state, action) {
      state.currentWebpageID = action.payload.currentWebpageID;
    },
    setCurrentWebpage(state, action) {
      state.currentWebpage = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebpageListByType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWebpageListByType.fulfilled, (state, action) => {
        state.status = "succeeded";
        adapter.upsertMany(state, action.payload);
      })
      .addCase(fetchWebpageListByType.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchWebpageList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWebpageList.fulfilled, (state, action) => {
        state.status = "succeeded";
        adapter.upsertMany(state, action.payload);
      })
      .addCase(fetchWebpageList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchWebpageByUrl.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWebpageByUrl.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload && adapter.upsertOne(state, action.payload);
        state.currentWebpage = action.payload;
      })
      .addCase(fetchWebpageByUrl.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
// =================================================
// EXPORT ACTIONS
export const { setCurrentWebpageId, setCurrentWebpage } =
  publicWebpagesSlice.actions;
// =================================================
// EXPORT SELECTORS
export const publicWebpageSelectors = adapter.getSelectors(
  (state) => state.publicWebpages
);

export const selectWebpagesByType = createSelector(
  [
    (state) => publicWebpageSelectors.selectAll(state),
    (state, type) => type,
  ],
  (webpages, type) => webpages.filter((webpage) => webpage.type === type)
);
// =================================================
// EXPORT REDUCER
export default publicWebpagesSlice.reducer;
