// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Component elements
import VolunteersContentMeasurementItem from "../volunteers/Volunteers_ContentMeasurementItem";
// -------------------------------------------------
// Redux
import {
  consumersSelectors,
  setCurrentStudyId,
} from "../../redux/reducers/consumers";
import { ticketsSelectors } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const StudiesContentTickets = (props) => {
  // ===============================================
  // TRANSLATION
  // -----------------------------------------------
  // TODO add translation
  // const { t } = useTranslation("components", {
  //   keyPrefix: "volunteers.Volunteers_ContentTimepoints",
  // });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [assortedTicketList, setAssortedTicketList] = useState({});
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state),
  ); // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const isOver = (d) => {
    return DateTime.fromISO(d) < DateTime.now();
  };
  // -----------------------------------------------
  const includesToday = (d1, d2) => {
    if (d2 === null) {
      return (
        DateTime.fromISO(d1).startOf("day") <= DateTime.now().startOf("day")
      );
    } else {
      return (
        DateTime.fromISO(d1).startOf("day") <= DateTime.now().startOf("day") &&
        DateTime.fromISO(d2).startOf("day") >= DateTime.now().startOf("day")
      );
    }
  };
  // -----------------------------------------------
  const includesTomorrow = (d1, d2) => {
    if (d2 === null) {
      return (
        DateTime.fromISO(d1).startOf("day") <=
        DateTime.now().plus({ days: 1 }).startOf("day")
      );
    } else {
      return (
        DateTime.fromISO(d1).startOf("day") <=
          DateTime.now().plus({ days: 1 }).startOf("day") &&
        DateTime.fromISO(d2).startOf("day") >=
          DateTime.now().plus({ days: 1 }).startOf("day")
      );
    }
  };
  // -----------------------------------------------
  // Sets the assorted ticket list when any of the tickets or the availability changes
  useEffect(() => {
    let key;
    if (!props.currentStudy) {
      return;
    }
    dispatch(setCurrentStudyId({ currentStudyId: props.currentStudy._id }));
    setAssortedTicketList(
      ticketList
        .filter((t) => t.studyId === props.currentStudy._id)
        .sort(
          (x, y) =>
            DateTime.fromISO(x.dateAvailable).valueOf() -
            DateTime.fromISO(y.dateAvailable).valueOf(),
        )
        .reduce((obj, t) => {
          let o = JSON.parse(JSON.stringify(t));
          if (o.repeat === 1) {
            o.isSeries = false;
          } else {
            o.isSeries = true;
          }
          if (o.isCompleted) {
            return obj.completed
              ? { ...obj, completed: [...obj.completed, o] }
              : { ...obj, completed: [o] };
          } else if (isOver(o.dateExpire)) {
            return obj.expired
              ? { ...obj, expired: [...obj.expired, o] }
              : { ...obj, expired: [o] };
          } else if (isOver(o.dateDue)) {
            return obj.overdue
              ? { ...obj, overdue: [...obj.overdue, o] }
              : { ...obj, overdue: [o] };
          } else if (includesToday(o.dateAvailable, o.dateDue)) {
            return obj.today
              ? { ...obj, today: [...obj.today, o] }
              : { ...obj, today: [o] };
          } else if (includesTomorrow(o.dateAvailable, o.dateDue)) {
            return obj.tomorrow
              ? { ...obj, tomorrow: [...obj.tomorrow, o] }
              : { ...obj, tomorrow: [o] };
          } else {
            key = o.dateAvailable.substring(0, 10);
            return obj[key]
              ? { ...obj, [key]: [...obj[key], o] }
              : { ...obj, [key]: [o] };
          }
        }, {}),
    );
  }, [props.currentStudy, ticketList]); // eslint-disable-line react-hooks/exhaustive-deps
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      <Card className="mb-3 px-3 py-2">
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemText
              primary={<Typography variant="h3">Scheduled tickets</Typography>}
            />
          </ListItem>
        </List>
        {assortedTicketList.expired && (
          <React.Fragment>
            <Divider className="mt-2" />
            <Typography variant="h6" color="red">
              Expired
            </Typography>
            {assortedTicketList.expired.map((ticket) => (
              <Grid
                key={ticket._id}
                container
                className="list-group-item-action"
              >
                <Grid xs={12} item>
                  <VolunteersContentMeasurementItem
                    showAvatar
                    isExpired
                    ticket={ticket}
                    currentConsumer={consumerEntities[ticket.userId]}
                  />
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}
        {assortedTicketList.overdue && (
          <React.Fragment>
            <Divider className="mt-2" />
            <Typography variant="h6" color="orangered">
              Overdue
            </Typography>
            {assortedTicketList.overdue.map((ticket) => (
              <Grid
                key={ticket._id}
                container
                className="list-group-item-action"
              >
                <Grid xs={12} item>
                  <VolunteersContentMeasurementItem
                    showAvatar
                    isOverdue
                    ticket={ticket}
                    currentConsumer={consumerEntities[ticket.userId]}
                  />
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}
        <Divider className="mt-2" />
        <Typography variant="h6">Today</Typography>
        {assortedTicketList.today ? (
          assortedTicketList.today.map((ticket) => (
            <Grid key={ticket._id} container className="list-group-item-action">
              <Grid xs={12} item>
                <VolunteersContentMeasurementItem
                  showAvatar
                  ticket={ticket}
                  currentConsumer={consumerEntities[ticket.userId]}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography variant="caption" color="GrayText">
            No scheduled tickets
          </Typography>
        )}
        <Divider className="mt-2" />
        <Typography variant="h6">Tomorrow</Typography>
        {assortedTicketList.tomorrow ? (
          assortedTicketList.tomorrow.map((ticket) => (
            <Grid key={ticket._id} container className="list-group-item-action">
              <Grid xs={12} item>
                <VolunteersContentMeasurementItem
                  showAvatar
                  ticket={ticket}
                  currentConsumer={consumerEntities[ticket.userId]}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography variant="caption" color="GrayText">
            No scheduled tickets
          </Typography>
        )}
        {Object.keys(assortedTicketList)
          .filter((key) => key[0] === "2") // Only keys that start with 2023-##-##
          .sort()
          .map((key) => (
            <React.Fragment key={key}>
              <Divider className="mt-2" />
              <Typography variant="caption">
                {DateTime.fromISO(key).toFormat("cccc, d LLLL yyyy")}
              </Typography>
              {assortedTicketList[key].map((ticket) => (
                <Grid
                  key={ticket._id}
                  container
                  className="list-group-item-action"
                >
                  <Grid xs={12} item>
                    <VolunteersContentMeasurementItem
                      showAvatar
                      ticket={ticket}
                      currentConsumer={consumerEntities[ticket.userId]}
                    />
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        {assortedTicketList.completed && (
          <React.Fragment>
            <Divider className="mt-2" />
            <Typography variant="h6" color="gray">
              Completed
            </Typography>
            {assortedTicketList.completed.map((ticket) => (
              <Grid
                key={ticket._id}
                container
                className="list-group-item-action opacity-25"
              >
                <Grid xs={12} item>
                  <VolunteersContentMeasurementItem
                    showAvatar
                    isCompleted
                    ticket={ticket}
                    currentConsumer={consumerEntities[ticket.userId]}
                  />
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}
      </Card>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesContentTickets;
